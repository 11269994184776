* {
  margin: 0;
  padding: 0;
}
.App {
  width: 100%;
  height: 0vh;
  font-family: sans-serif;
  text-align: center;
}
.App h1 {
  font-size: 12px;
  margin-top: 10px;
  text-shadow: 2px 2px 10px rgb(180, 179, 179);
}
.clock {
  display: flex;
  flex-direction: column;
  align-items: start;
  /*
  font-size: 10vw;
  justify-content: center;
  text-align: center;
  border: 2px solid black;
  padding: 15px 10px;
  background-color: #000;
  margin: 4vh 50px;
  box-shadow: 5px 5px 8px rgb(145, 143, 143), -5px -5px 8px rgb(145, 143, 143);
  */
}

.clock span {
  color: #00000000;
  animation: 30s infinite 10s chgcolor;
}

@keyframes chgcolor {
  2% {
    color: hotpink;
  }
  25% {
    color: rgb(105, 255, 130);
  }
  75% {
    color: rgb(138, 105, 255);
  }
  100% {
    color: rgb(231, 133, 21);
  }
}