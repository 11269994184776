.App {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  gap: 1rem;
  padding-right: 1rem;
}

.App-header {
  width: 100%;
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 0.5em;
  color: white;
  text-align: center;
  /* padding: 3px; */
}

.wrapper-class {
  width: 100%;
  display: flex;
  background-color: #fafafa;
  border: 0;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.editor-class {
  margin: auto;
  width: 95%;
  padding: 0 1rem;
  background-color: #ffffff;
  border-radius: 25px;
  margin: 0.45rem 0;
}

.toolbar-class {
  background-color: #fafafa;
  font-size: 0.8em;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  width: 13em;
  height: 2.5em;
  overflow: hidden;
  border: 0;
  margin: 0;
  padding: 0;
  gap: 1em;
  /* justify-content: center; */
}

.toolbar-class div {
  background-color: #fafafa;
  border-radius: 3px;
  border: 0;
  margin: 0.2em;
  padding: 0;
  /* width: 3em; */
  /* height: 3em; */
  /* border-radius: 50%; */
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 0 0 1px #ccc; */
  /* cursor: pointer; */
  /* outline: none; */
}